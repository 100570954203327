import request from '../utils/request'

// API-F86 - [EzshipController] ezship/getTransaction 取得Ezship電子超商資訊
export function getTransaction (params) {
  return request({
    url: `/ezship/getTransaction`,
    method: 'get',
    params
  })
}
